import http from './http'
const mainUri = '/api/v2/evaluate'

export function store(data) {
  return http({
    url: `${mainUri}`,
    method: 'post',
    data,
  })
}
