<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    :items="lists"
    :options.sync="options"
    :items-per-page.sync="tableMeta.per_page"
    :server-items-length="tableMeta.total"
    :item-class="itemBg"
    :footer-props="footer"
    class="elevation-1 row-pointer"
    @click:row="rowClick"
    @dblclick:row="rowDblClick"
  >
    <!-- <template v-slot:[`item.no`]="{ item, index }">
      {{ ((index +1) + ( (options?.page - 1) * tableMeta?.per_page) ) }}
    </template> -->
    <template v-slot:[`item.name`]="{ item }">
      <template v-if="item.is_active == 1">
        <v-icon color="red">mdi-check</v-icon>
        {{ item.name }}
      </template>
      <template v-else>
        {{ item.name }}
      </template>
    </template>
    <template v-slot:[`item.start_at`]="{ item }">
      {{ thDate(item.start_at) }}
    </template>
    <template v-slot:[`item.end_at`]="{ item }">
      {{ thDate(item.end_at) }}
    </template>
    <template v-slot:[`item.types`]="{ item }">
      {{  item.types && item.types.length > 0 ? item.types.map(function(elem){
              return elem.name;
          }).join(",") 
          :
          item.type
      }}
    </template>
    <template v-slot:[`item.is_active`]="{ item }">
      <v-chip v-if="item.is_active===1" color="green">
        ตั้งจริง
      </v-chip>
      <v-chip v-if="item.is_active===2" color="red">
        ไม่ได้ตั้ง
      </v-chip>
      <v-chip v-if="item.is_active===0" color="gray">
        ยังไม่ระบุ
      </v-chip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-speed-dial direction="left" open-on-hover>
        <v-btn color="blue darken-4" icon slot="activator" small dark fab>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="item.is_active == 1 ? 'red' : 'grey'"
              fab
              small
              v-on="on"
              v-bind="attrs"
              @click.prevent.stop="$emit('show-status-dialog', item)"
              v-show="item.can.update"
            >
              <v-icon color="white">
                mdi-traffic-cone
              </v-icon>
            </v-btn>
          </template>
          <span>{{ item.is_active == 1 ? 'เปลี่ยนสถานะ' : 'เปลี่ยนสถานะ' }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              fab
              v-show="item.can.open"
              @click.prevent.stop="$emit('show-qrcode', item.id)"
              color="blue"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon color="white">
                mdi-qrcode
              </v-icon>
            </v-btn>
          </template>
          <span>พิมพ์ QRcode</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              color="green"
              v-show="item.can.update"
              v-on="on"
              v-bind="attrs"
              @click.prevent.stop="$emit('show-evaluate-result', item)"
              v-if="item.is_active == 1"
            >
              <v-icon color="white">
                mdi-text-account
              </v-icon>
            </v-btn>
          </template>
          <span>ผลการประเมิน</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              color="yellow"
              v-on="on"
              v-bind="attrs"
              @click.prevent.stop="$emit('show-eva-cmd', item.id)"
              v-if="item.is_active == 1"
              v-show="item.can.evaluate"
            >
              <v-icon>
                mdi-check-box-multiple-outline
              </v-icon>
            </v-btn>
          </template>
          <span>ประเมินจุดตรวจ</span>
        </v-tooltip>
      </v-speed-dial>
    </template>
    <checkpoint-dialog ref="dialog" v-model="showDialog" @saved="onSave"></checkpoint-dialog>
  </v-data-table>
</template>

<script>
import { listAll, toggleActive } from '@/api/checkpoint'
import { mapGetters } from 'vuex'
import checkpointDialog from '../components/checkpointDialog'
export default {
  methods: {
    onSave() {
      
    },
    getList() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      const param = {
        page: page,
        limit: itemsPerPage,
        bureau: this.bureauId ?? undefined,
        division: this.divisionId ?? undefined,
        station: this.stationId ?? undefined,
        start: this.dateStart ?? undefined,
        end: this.dateEnd ?? undefined,
        showActive: this.showActive,
        showInActive: this.showInActive,
        checkpointType: this.checkpointType
      }
      this.lists = []
      listAll(param)
        .then(res => {
          this.lists = res?.data
          let meta = res?.meta
          let itemsPerPage = parseInt(meta?.per_page)
          this.tableMeta = {
            itemsPerPage: itemsPerPage,
            per_page: itemsPerPage,
            page: meta?.current_page,
            total: meta?.total,
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    setActive(id) {
      const index = this.lists.findIndex(l => l.id == id)
      toggleActive(id).then(res => {
        if (res.success) {
          this.$toast.success({
            title: 'Info',
            message: 'เปลี่ยนสถานะเรียบร้อยแล้ว',
          })
          this.lists[index].is_active = res.active
        }
      })
    },
    rowClick(e, data) {
      this.$emit('row-click', data.item)
    },
    rowDblClick(e, data) {
      // console.log(data.item)
      this.$emit('row-dbl-click', data.item)
    },
    // utils
    thDate(d) {
      return this.moment(d)
        .add(543, 'year')
        .format('D MMMYYYY HH:mm')
    },
    itemBg(item) {
      return item.is_active == 1 ? 'green lighten-4' : ''
    },
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    showActive: {
      type: Boolean,
      default: false,
    },
    showInActive: {
      type: Boolean,
      default: false,
    },
    checkpointType: {
      type: Number,
      default: undefined,
    },
  },
  watch: {
    options: {
      handler() {
        this.getList()
      },
      deep: true,
    },
    bureauId(v) {
      this.getList()
    },
    divisionId(v) {
      this.getList()
    },
    stationId(v) {
      this.getList()
    },
    dateEnd(v) {
      this.getList()
    },
    showActive() {
      this.getList()
    },
    showInActive() {
      this.getList()
    },
    checkpointType() {
      this.getList()
    },
  },
  created() {
    // this.getList()
  },
  components: {
    checkpointDialog
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      headers: [
        // {
        //   text: 'ลำดับ',
        //   sortable: false,
        //   value: 'no',
        // },
        {
          text: 'จุดตรวจ',
          sortable: false,
          value: 'name',
        },
        {
          text: 'ประเภท',
          sortable: false,
          value: 'types',
        },
        {
          text: 'สถานที่',
          sortable: false,
          value: 'address',
        },
        {
          text: 'เริ่ม',
          sortable: false,
          value: 'start_at',
        },
        { text: 'ถึง', value: 'end_at', sortable: false },
        {
          text: 'หัวหน้าจุดตรวจ',
          value: 'chief',
          sortable: false,
        },
        {
          text: 'เบอร์โทรศัพท์',
          value: 'chief_phone',
          sortable: false,
        },
        {
          text: 'สถานี',
          value: 'station',
          sortable: false,
        },
        {
          text: 'บก./ภจว.',
          value: 'division',
          sortable: false,
        },
        {
          text: 'บช.',
          value: 'bureau',
          sortable: false,
        },
        {
          text: 'สถานะ',
          value: 'is_active',
          sortable: false,
        },
        {
          text: 'หมายเหตุ',
          value: 'remark',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      footer: {
        itemsPerPageOptions: [10, 50, 100, 200],
        showCurrentPage: true,
        showFirstLastPage: true,
      },
      tableMeta: {
        itemsPerPage: 10,
        per_page: 10,
        page: 1,
        total: 0,
      },
      lists: [],
      options: {},
    }
  },
  computed: {
    ...mapGetters('Appfilter', ['bureauId', 'divisionId', 'stationId', 'dateStart', 'dateEnd']),
  },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
