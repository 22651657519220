<template>
  <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition" :overlay="false">
    <v-card>
      <v-card-title>
        ประเมินผลจุดตรวจ
        <v-spacer></v-spacer>
        <v-btn text @click="close">ปิด</v-btn>
      </v-card-title>
      <v-stepper v-model="stepperValue">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="stepperValue > 1" editable>รูปแบบและมาตรฐานของจุดตรวจ</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="stepperValue > 2" editable
            >รูปแบบและมาตรฐานจุดตรวจวัดแอลกอฮอล์</v-stepper-step
          >
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="stepperValue > 3">ข้อคิดเห็นอื่นๆ</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card color="grey lighten-1" class="mb-5 my-auto mx-auto">
              <question-card
                v-for="(q, i) in questionStep1"
                :key="`${i} - ${q.id}`"
                :question="q"
                v-model="answers[i]"
              ></question-card>
            </v-card>
            <v-btn color="primary" @click="stepperValue = 2">ต่อไป</v-btn>
            <v-btn text @click="close">Cancel</v-btn>
          </v-stepper-content>
        </v-stepper-items>
        <v-stepper-items>
          <v-stepper-content step="2">
            <v-card color="grey lighten-1" class="mb-5 my-auto mx-auto">
              <question-card
                v-for="(q, i) in questionStep2"
                :key="`${i} - ${q.id}`"
                :question="q"
                v-model="answersStep2[i]"
              ></question-card>
            </v-card>
            <v-btn color="primary" @click="stepperValue = 3">ต่อไป</v-btn>
            <v-btn text @click="close">Cancel</v-btn>
          </v-stepper-content>
        </v-stepper-items>
        <v-stepper-items>
          <v-stepper-content step="3">
            <v-card flat class="mb-5 my-auto mx-auto">
              <v-card-text>
                <v-text-field name="comment" label="ข้อเสนอแนะ" v-model="evaAnswer.comment"></v-text-field>
              </v-card-text>
            </v-card>
            <v-btn color="primary" @click="save">บันทึกข้อมูล</v-btn>
            <v-btn text @click="close">Cancel</v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
  </v-dialog>
</template>

<script>
import { question } from '@/api/evaq'
import { store } from '@/api/evaluate'
import questionCard from './question'
const evaAnswer = {
  comment: '',
  details: [],
}
const detailModel = {}
export default {
  methods: {
    show(id) {
      this.evaAnswer.checkpointId = id
      this.questionStep1.forEach((v, i) => {
        this.answers.push({
          evaluate_question_id: v.id,
          answer: 0,
          answer2: 0,
          remark: '',
        })
      })

      this.questionStep2.forEach((v, i) => {
        this.answersStep2.push({
          evaluate_question_id: v.id,
          answer: 0,
          answer2: 0,
          remark: '',
        })
      })

      this.dialog = true
    },
    close() {
      this.evaAnswer = evaAnswer
      this.answers = []
      this.detailModel = detailModel
      this.stepperValue = 1
      this.dialog = false
    },
    save() {
      this.evaAnswer.details.push(...this.answers, ...this.answersStep2)

      console.log('save to id : ' + this.checkpointId)
      console.log(this.evaAnswer)

      this.loading = true
      store(this.evaAnswer)
        .then(res => {
          if (res.success) {
            this.$refs.confirm.open('บันทึก', 'บันทึกข้อมูลเรียบร้อยแล้ว', {
              color: 'indigo',
              noconfirm: true,
            })
            this.close()
          }
        })
        .catch(err => {
          this.$refs.confirm.open('Error', 'เกิดข้อผิดพลาดระหว่างทำรายการ', {
            color: 'red',
            noconfirm: true,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  async created() {
    this.questions = await question()
  },
  computed: {
    questionStep1() {
      return this.questions.filter(({ id }) => id < 20)
    },
    questionStep2() {
      return this.questions.filter(({ id }) => id > 20)
    },
  },
  components: {
    questionCard,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      stepperValue: 1,
      answers: [],
      answersStep2: [],
      checkpointId: 0,
      detailModel: {
        questionId: 0,
        answer: 1,
        remark: '',
      },
      evaAnswer: {
        checkpointId: 0,
        comment: '',
        details: [],
      },
      questions: [],
    }
  },
}
</script>

<style></style>
