<template>
  <v-card elevation="2" class="mx-auto">
    <v-card-text>
      {{ question.question }}
      <div>
        <v-btn-toggle v-model="model.answer" mandatory>
          <v-btn color="grey lighten-1">มี/ครบ</v-btn>
          <v-btn color="grey lighten-1">ไม่มี/ไม่ครบ</v-btn>
        </v-btn-toggle>
        <v-btn-toggle v-model="model.answer2" mandatory>
          <v-btn color="grey lighten-1 ">เป็นไปตามมาตรฐาน</v-btn>
          <v-btn color="grey lighten-1">ไม่เป็นไปตามมาตรฐาน</v-btn>
        </v-btn-toggle>
      </div>
      <div>
        <v-text-field name="remark" label="หมายเหตุ" v-model="model.remark"></v-text-field>
      </div>
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>
</template>

<script>
export default {
  methods: {
    senddata() {
      this.$emit('input', {
        questionId: this.question.id,
        answer1: this.answer1,
        answer2: this.answer2,
        remark: '',
      })
    },
  },
  data() {
    return {
      answer1: 0,
      answer2: 0,
      remark: '',
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  props: {
    question: {},
    value: {
      type: Object,
      default() {
        return {
          evaluate_question_id: 0,
          answer: 0,
          answer2: 0,
          remark: '',
        }
      },
    },
  },
}
</script>

<style></style>
