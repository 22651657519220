<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :overlay="false"
    class="mx-auto my-auto"
    max-width="1000px"
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title>
        ผลการประเมินจากประชาชน
      </v-card-title>
      <v-card-text>
        <iframe :src="url" frameborder="0" style="border:0px;width:900px;height:500px"></iframe>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" text>ปิด</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      url: '',
    }
  },
  methods: {
    show(id, start, end) {
      // `https://checkpointrate.police.go.th/result_v2.php?date1=${start}&date2=${end}&chk_point_id=${id}`
      // this.url = `https://checkpointrate.police.go.th/result_v2.php?date1=${start}&chk_point_id=${id}`
      this.url = `https://tpcc.police.go.th/satisfaction?date=${start}&checkpoint_id=${id}`

      this.dialog = true
    },
  },
  created() {},
}
</script>

<style></style>
