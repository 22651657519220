<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <v-card :loading="loading">
      <v-card-title class="headline">
        <v-icon color="blue">
          mdi-traffic-cone
        </v-icon>
        อัพเดทสถานะจุดตรวจ
        <span class="subtitle-2"> {{ type == 'add' ? '' : '(แก้ไข)' }}</span>
      </v-card-title>
      <v-container fluid>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-row>
            <v-col sm="12"></v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-radio-group v-model="isActive" :rules="reqRule">
                <v-radio label="ตั้งจริง" value="1"></v-radio>
                <v-radio label="ไม่ได้ตั้ง" value="2"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea label="ระบุปัญหา ข้อขัดข้อง และอื่นๆ หากตั้งจุดตรวจไม่ได้" auto-grow
                v-model="remark"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-alert v-show="errorMsg && errorMsg.length > 0" type="error">{{ errorMsg }}</v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-btn text @click="cancel" v-show="type !== 'view'">ยกเลิก</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-3" v-show="type !== 'view'" @click="submit" dark>
          <v-icon>
            mdi-content-save
          </v-icon>
          บันทึกข้อมูล
        </v-btn>
        <v-btn text color="red" @click="cancel" v-show="type === 'view'">ปิด</v-btn>
      </v-card-actions>
    </v-card>
    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
  </v-dialog>
</template>

<script>
import { show, store, update, updateActive } from '@/api/checkpoint'

export default {
  methods: {
    submit() {
      this.errorMsg = "";
      if (this.isActive === null || this.isActive === undefined) {
        this.errorMsg = "กรุณาระบุสถานะการตั้งจุดตรวจ";
      } else if (this.isActive === "2" && !this.remark) {
        this.errorMsg = "กรุณาระบุรายละเอียด";
      } else {
        this.loading = true
        const saveData = {
          is_active: this.isActive,
          remark: this.remark
        }
        updateActive(this.id, saveData)
          .then(res => {
            this.$toast.success({
              title: 'Success',
              message: 'แก้ไขข้อมูลเรียบร้อยแล้ว',
            })
            this.$emit('saved')
            this.cancel()
          })
          .catch(err => {
            console.log(err)
            this.$toast.error({
              title: 'Error',
              message: 'เกิดข้อผิดพลาดระหว่างทำรายการ',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }

      
    },
    clear() {
      this.$refs.form.reset()
    },
    async init(item) {
      
      this.id = item.id;
      this.remark = item.remark ? item.remark : null
      this.isActive = item.is_active ? item.is_active + "" : "1"
    },
    cancel() {
      this.data = Object.assign({}, this.data_init)
      this.$refs.form.resetValidation()
      this.file = null
      this.dialog = false
    },
  },
  data: () => ({
    id: null,
    isActive: "1",
    remark: "",
    data: {},
    errorMsg: "",
    data_init: { eval1: "1", eval2: "1", detail: "", images: [] },
    detail: {},
    valid: true,
    loading: false,
    levels: [],
    type: 'add',
    datemenu: false,
    bdatemenu: false,
    readerCompleted: false,
    file: null,
    checkpoints: [],
    officers: [],
    fullName: "",
    arresttypes: [
      { value: 1, text: 'จับกุม' },
      { value: 2, text: 'ว่ากล่าวตักเตือน' },
      { value: 3, text: 'รายงานผลการปฏิบัติ' },
    ],
    charges: [],
    chargetype: [],
    detail_init:
      'เรียนผู้บังคับบัญชา เพื่อโปรดทราบ\r\n #date เวลา #time #officers ได้ทำการ #type (ผู้ต้องหา) เนื่องมาจาก #charges ',
    arrestreport: {},
    reqRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
    cidRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล', v => /\d{13}/.test(v) || 'กรุณากรอกเป็นตัวเลขเท่านั้น'],
    phoneRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล', v => /\d{9,10}/.test(v) || 'กรุณากรอกเป็นตัวเลขเท่านั้น'],
    timeRules: [v => /^((?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?))$/.test(v) || 'กรอกเวลาให้ถูกต้อง'],
  }),
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    }
  },
  watch: {
    bdatemenu(val) {
      val && setTimeout(() => (this.$refs.bdatePicker.activePicker = 'YEAR'))
    },
  },
  async created() {
    // this.checkpoints = await havePast()
    // this.charges = await getCharge()
    // this.chargetype = await getChargeType()
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
}
</script>

<style>

</style>
