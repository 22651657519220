import http from './http'
const mainUri = '/api/v2/evaq'

export function question(id) {
  return http({
    url: `${mainUri}`,
    method: 'get',
    params: { id: id },
  })
}
