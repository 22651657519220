<template>
  <v-container fluid>
    <v-card elevation="2" :loading="loading">
      <v-card-title primary-title>
        <div>
          <div class="headline">
            <v-icon color="blue darken-3">
              mdi-traffic-cone
            </v-icon>
            จุดตรวจทั้งหมด
          </div>
          <span class="grey--text subtitle-2">ข้อมูลจุดตรวจที่มีทั้งหมด(ไม่รวมด่านตรวจและจุดสกัด)</span>
        </div>
        <v-spacer></v-spacer>
        <!-- <v-select
          :items="checkpointTypes"
          label="เลือกประเภท"
          style="width:40px; !important"
          v-model="checkpointType"
          hide-details
          single-line
          dense
          solo-inverted
        ></v-select> -->
        <v-select
          :items="checkpointTypes"
          v-model="checkpointType"
          item-text="name"
          item-value="id"
          label="เลือกประเภท"
          style="margin-right: 5px;"
          solo-inverted
          :disabled="checkpointTypes.length == 0 || loading"
          hide-details
          single-line
          clearable
          class="shrink pr-1 m-1"
        >
        </v-select>
        <v-switch
          v-model="showActive"
          :disabled="showInActive || loading"
          label="แสดงเฉพาะที่ตั้งจริง"
          :hide-details="true"
          style="margin-right: 5px;"
          inset
        ></v-switch>
        <v-switch
          v-model="showInActive"
          :disabled="showActive || loading"
          label="แสดงเฉพาะที่ไม่ได้ตั้ง"
          :hide-details="true"
          inset
          style="margin-left: 5px;"
        ></v-switch>
        <v-btn color="indigo darken-2" dark class="ml-2" @click="exportClick" :disabled="loading">
          {{this.loading ? 'Exporting please wait...' : 'Export Excel'}}
        </v-btn>
      </v-card-title>
      <checkpoint-table
        ref="cpTable"
        @row-click="rowClick"
        @row-dbl-click="rowDblClick"
        @show-evaluate-result="showEvaluateResult"
        @show-qrcode="showQrcode"
        @show-eva-cmd="showEvaCmd"
        @show-status-dialog="showStatusDialog"
        :show-active="showActive"
        :show-in-active="showInActive"
        :checkpoint-type="checkpointType"
      ></checkpoint-table>
    </v-card>

    <!-- pdf dialog -->
    <pdf-dialog ref="pdf"></pdf-dialog>

    <!-- detail dialog -->
    <checkpoint-dialog ref="cdialog" v-model="dialog" :visible="dialog" />

    <!-- evaluate dialog -->
    <evaluate-dialog ref="edialog"></evaluate-dialog>

    <!-- commander eva dialog -->
    <evaluate-cmd-dialog ref="evacmddlg"></evaluate-cmd-dialog>

    <checkpoint-status-dialog ref="csDialog" v-model="showCsDialog" @saved="onSave"></checkpoint-status-dialog>
  </v-container>
</template>

<script>
import checkpointTable from './partials/checkpointTable'
import pdfDialog from '@/components/pdfDialog'
import checkpointDialog from '@/components/checkpointDetailDialog'
import evaluateDialog from './components/evaluateDialog'
import checkpointStatusDialog from './components/checkpointDialog'
import evaluateCmdDialog from '@/components/evaluateCmdDialog'
import { qrcode, exportAllExcel } from '@/api/checkpoint'
import { mapGetters } from 'vuex'

export default {
  methods: {
    exportClick() {
      // const url = 'https://tpcc.police.go.th/2021/api/v2/checkpointAllExportNoAuth';
      // window.location.href = url;

      if (!this.checkpointType || !this.dateStart || !this.dateEnd) {
        alert('เนื่องจากข้อมูลมีจำนวนมาก กรุณาเลือกตัวกรอง ประเภทด่านตรวจหรือห้วงวันที่ก่อนทำการ export ข้อมูล')
        return;
      }

      this.loading = true
      const params = {
        bureau: this.bureauId,
        division: this.divisionId,
        station: this.stationId,
        start: this.dateStart,
        end: this.dateEnd,
        showActive: this.showActive,
        showInActive: this.showInActive,
        checkpointType: this.checkpointType
      }
      exportAllExcel(params).then(res => {
        this.loading = false
      })
    },
    rowClick(item) {

    },
    rowDblClick(item) {
      this.$refs.cdialog.open(item.id)
    },
    showEvaluateResult(item) {
      this.$refs.edialog.show(item.id, item.start_at, item.end_at)
    },
    showEvaCmd(id) {
      this.$refs.evacmddlg.show(id)
    },
    showStatusDialog(item) {
      this.$refs.csDialog.init(item)
      this.showCsDialog = true;
    },
    async showQrcode(id) {
      this.loading = true
      const pdfBase64 = await qrcode(id)
      this.$refs.pdf.open(pdfBase64.pdf)
      this.loading = false
    },
    onSave() {
      this.showCsDialog = false
      this.$refs.cpTable.getList()
    }
  },
  components: {
    checkpointTable,
    pdfDialog,
    checkpointDialog,
    evaluateDialog,
    evaluateCmdDialog,
    checkpointStatusDialog
  },
  data() {
    return {
      loading: false,
      dialog: false,
      showActive: false,
      showInActive: false,
      showCsDialog: false,
      checkpointType: undefined,
      checkpointTypes: [
        { id: 1, name: 'จุดกวดขันวินัยจราจร' },
        { id: 2, name: 'จุดตรวจวัดแอลกอฮอล์' },
        { id: 3, name: 'เครื่องวัดความเร็วแบบเคลื่อนที่' },
        { id: 4, name: 'จุดตรวจตาม พรก.ฉุกเฉิน' },
        { id: 5, name: 'จุดตรวจสอบน้ำหนักรถบรรทุก' },
        { id: 6, name: 'ป้องกันปราบปรามอาชญากรรม' },
        { id: 7, name: 'ตรวจยาเสพติด' },
        { id: 8, name: 'ป้องกันการแพร่ระบาดโควิด' },
        { id: 9, name: 'ควันดำ/เสียงดัง' },
        { id: 10, name: 'ความมั่นคงตามแนวชายแดน' },
        { id: 11, name: 'อื่นๆ' },
      ]
    }
  },
  computed: {
    ...mapGetters('Appfilter', ['bureauId', 'divisionId', 'stationId', 'dateStart', 'dateEnd']),
  },
  async created() {},
}
</script>

<style></style>
